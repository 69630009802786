import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import SEO from '../components/seo';
import Layout from '../components/layout';
import BackgroundImage from 'gatsby-background-image';
import styles from './fachgebiete.module.css';

class Fields extends React.Component {
  render() {
    const page = get(this, 'props.data.contentfulSeite');
    const sponsors = get(this, 'props.data.allContentfulSponsor.edges');
    const fields = get(this, 'props.data.allContentfulFachgebiet.edges');

    return (
      <Layout location={this.props.location} sponsors={sponsors}>
        <SEO title={page.title} />
        <BackgroundImage backgroundColor="#0e3920" className="header" fluid={page.heroImage.fluid}>
          <h1>Fachgebiete</h1>
        </BackgroundImage>
        <div className="container">
          {fields.map(({ node }, index) => (
            <Link key={index} className={styles.fieldContainer} to={`/fachgebiete/${node.slug}`}>
              <BackgroundImage
                className={styles.field}
                key={index}
                fluid={node.heroImage ? node.heroImage.fluid : {}}
              >
                <h2>{node.name}</h2>
                <span>Mehr erfahren &#x2192;</span>
              </BackgroundImage>
            </Link>
          ))}
        </div>
      </Layout>
    )
  }
}

export default Fields;

export const pageQuery = graphql`
  query FieldsQuery {
    contentfulSeite(slug: { eq: "fachgebiete" }) {
      title
      heroImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulSponsor(filter: { id: { ne: "10296087-115a-526e-9f65-04404f89693a" }}) {
      edges {
        node {
          name
          website
          logo {
            fixed(width: 250, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
    allContentfulFachgebiet(sort: { fields: order, order: ASC }) {
      edges {
        node {
          name
          slug
          heroImage {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
